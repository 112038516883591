import { useEffect, useState } from 'react';
import { backupperAPI } from '../utils/axios';

export enum API_URL {
  ORDER_LIST = '/orders/list/6',
  ORDER = 'orders',
  
  COMPANIES_ADD = '/orders/add',
  EXPORT= '/orders/export/6',
  SET_TRACING = '/orders/tracking',
  PACKAGES = '/campaigns',
  UPDATES = '/api/service/updates',
  BACKUPS = '/api/service/backups',
  VERSIONS = '/api/service/versions',
}

export interface UrlConfig<P> {
  url: API_URL | undefined;
  params?: P;
  id?: string;
}

export function useFetch<R>(urlConfig: UrlConfig<any>, loadingInitialParam?: boolean) {
  const loadingInitial =
    loadingInitialParam !== null && loadingInitialParam !== undefined ? loadingInitialParam : true;
  const [loading, setLoading] = useState<boolean>(loadingInitial);
  const [data, setData] = useState<R | null>(null);
  const [error, setError] = useState<any | null>(null);
  const fetchUrl =
    urlConfig.url && urlConfig.id
      ? `${urlConfig.url}/${urlConfig.id}`
      : urlConfig.url
      ? urlConfig.url
      : undefined;
  useEffect(() => {
    if (loading && fetchUrl) {
      backupperAPI
        .get(fetchUrl, { params: urlConfig.params })
        .then((resp) => {
          setData(resp.data);
          setError(null);
        })
        .catch((error) => {
          setError(error);
          setData(null);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [urlConfig.url, urlConfig.params, loading]);

  return {
    data,
    error,
    loading,
    setLoading,
  };
}
